import { ToastContainer } from "react-toastify";
import { CardBody, Container, Card, Row } from "reactstrap";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    flexRender,
    getPaginationRowModel
} from "@tanstack/react-table";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { fetchPropPlayerSessions } from '@/services/users/users-rest';
import { useQuery } from "@tanstack/react-query";
import CopyField from "@/common/forms/CopyField";
import config from '@/config/index';
import { format } from 'date-fns';

import './prop-player-table.css';

const PropPlayers = () => {
    const { data, isLoading, error } = useQuery({
        queryKey: ["propPlayerSessions"],
        queryFn: () => fetchPropPlayerSessions(),
    });

    const columns = [
        {
            id: 'alias',
            header: 'Alias',
            accessorKey: 'user.alias',
        },
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'user.firstName',
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'user.lastName',
        },
        {
            id: 'smartWallet',
            size: 200,
            header: 'Smart Wallet',
            accessorKey: 'user.smartWalletAddress',
            cell: (info: any) => {
                const cellData = info.getValue();
                return (
                    <CopyField
                        className="w-100"
                        name="userSmartWallet"
                        label={false}
                        value={cellData}
                        link={`${config.blockExplorerUrl}/address/${cellData}`}
                    />
                );
            },
        },
        {
            id: 'email',
            header: 'Email',
            size: 200,
            accessorKey: 'user.email',
            cell: (info: any) => {
                const cellData = info.getValue();
                return (
                    <CopyField
                        className="w-100"
                        name="email"
                        label={false}
                        value={cellData}
                    />
                );
            },
        },
        {
            id: 'tableName',
            header: 'Table Name',
            accessorKey: 'table.name',
        },
        {
            id: 'startTime',
            header: 'Start Time',
            accessorKey: 'startTime',
            cell: (info: any) => format( info.getValue(), 'yyyy-MM-dd HH:mm' )
        },
        {
            id: 'endTime',
            header: 'End Time',
            accessorKey: 'endTime',
            cell: (info: any) => format( info.getValue(), 'yyyy-MM-dd HH:mm' )
        },
        {
            id: 'minutesPlayed',
            header: 'Minutes Played',
            accessorKey: 'minutesPlayed',
        },
        {
            id: 'hourlyRate',
            header: 'Hourly Rate',
            accessorKey: 'hourlyRate',
            cell: (info: any) => `$${info.getValue()}`,
        },
        {
            id: 'sessionId',
            header: 'Session ID',
            accessorKey: 'sessionId',
            cell: (info: any) => info.getValue() ? info.getValue() : "NULL"
        }
    ]

    const table = useReactTable({
        data: data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        columnResizeDirection: 'ltr'
    });

    return (
        <div className="page-content">
            <Container fluid>
                <Row className="gy-2 mb-2">
                    <Card className="card mb-2">
                        <CardBody className="overflow-auto">
                            <table className="table table-container" style={{ width: table.getCenterTotalSize() }}>
                                <thead>
                                    {table.getHeaderGroups().map((headerGroup) => {
                                        return (
                                            <tr key={headerGroup.id}>
                                                {headerGroup.headers.map((header) => {
                                                    return (
                                                        <th
                                                            key={header.id}
                                                            colSpan={header.colSpan}
                                                            onClick={header.column.getToggleSortingHandler()}
                                                            style={{ width: header.getSize(), textAlign: 'left' }}
                                                            className="table-header"
                                                        >
                                                            <div className="table-header-content">
                                                                {header.isPlaceholder ? null : flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                                <div>
                                                                            {{
                                                                            asc: <FaArrowDown />,
                                                                            desc: <FaArrowUp />,
                                                                            default: (
                                                                                <span>
                                                                                <FaArrowDown/>
                                                                                <FaArrowUp />
                                                                                </span>
                                                                            )
                                                                            }[header.column.getIsSorted() as string || 'default']}          
                                                                </div>
                                                            </div>
                                                            <div
                                                                    {...{
                                                                        onDoubleClick: () => header.column.resetSize(),
                                                                        onMouseDown: header.getResizeHandler(),
                                                                        onTouchStart: header.getResizeHandler(),
                                                                        className: `resizer ${
                                                                            table.options.columnResizeDirection
                                                                          } ${
                                                                            header.column.getIsResizing() ? 'isResizing' : ''
                                                                          }`,
                                                                    }}
                                                                />
                                                            
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map((row) => (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map((cell: any) => (
                                                    <td className="table-cell" key={cell.id} style={{ width: cell.column.getSize() }}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
};

export default PropPlayers;