import { AgreementsStrategyConfig } from './agreements-config.interface';

export const productionConfig:  AgreementsStrategyConfig = {
  race_for_tokens: {
      // Configuration for the race for tokens promotions
      name: "race_for_tokens",
      startDate: "2024-01-01",
      endDate: "2024-12-31",
      referralReward: 100,
      referrerReward: 100,
      bonusReferralThresholds: [
          { threshold: 5, bonusReward: 300 }, // Example: 4000 bonus tokens for 50 referrals
          { threshold: 20, bonusReward: 1000 }, // Example: 8000 bonus tokens for 100 referrals
          { threshold: 50, bonusReward: 3000 }, // Example: 20000 bonus tokens for 250 referrals
      ],
      upstreamReferralShare: 0,
      qualifiedReferralThreshold: 100,
      // isUniversal: true,
  },
  pre_launch_ambassador: {
      // Configuration for the pre-launch ambassador strategy
      // Example: Set the amount of generated rake required to qualify for the referral program
      name: "pre_launch_ambassador",
      startDate: "2024-01-01",
      endDate: "2024-12-31",
      referralReward: 100,
      referrerReward: 100,
      // ambassadors don't get bonuses on direct user referrals only on sub-agent referrals
      // we could consider removing the bonusReferralThresholds from this strategy and when
      // checking if nothing is found, we can check if there is a valid agreement they
      // fall under and apply the bonus from that agreement
      // we could also add a flag for agreements that need to be manually assigned vs auto assigned
      // ie. pre_launch_ambassador needs to be assigned by the phenom team, race_for_tokens is auto assigned
      bonusReferralThresholds: [
          { threshold: 5, bonusReward: 300 }, // Example: 4000 bonus tokens for 50 referrals
          { threshold: 20, bonusReward: 1000 }, // Example: 8000 bonus tokens for 100 referrals
          { threshold: 50, bonusReward: 3000 }, // Example: 20000 bonus tokens for 250 referrals
      ],
      upstreamReferralShare: 0.4,
      qualifiedReferralThreshold: 100,
      // isUniversal: false,
  },
  pre_launch_agent: {
      // Configuration for the pre-launch agent strategy
      // Example: Set the amount of generated rake required to qualify for the referral program
      name: "pre_launch_agent",
      startDate: "2024-01-01",
      endDate: "2024-12-31",
      referralReward: 100,
      referrerReward: 100,
      qualifiedReferralThreshold: 100,
      bonusReferralThresholds: [
          { threshold: 50, bonusReward: 4000 }, // Example: 4000 bonus tokens for 50 referrals
          { threshold: 100, bonusReward: 8000 }, // Example: 8000 bonus tokens for 100 referrals
          { threshold: 250, bonusReward: 20000 }, // Example: 20000 bonus tokens for 250 referrals
          { threshold: 500, bonusReward: 40000 }, // Example: 40000 bonus tokens for 500 referrals
          // Add more thresholds as needed
      ],
      upstreamReferralShare: 0.2,
      // isUniversal: false,
  },
};