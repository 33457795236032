import { api } from "@/services/api";
import { UpdateUserDto, UserQueryDto, UserInfo } from "./user-types";
import { GetUserDto, UserResponseDto } from "./user-filters";

export const fetchPropPlayerSessions = async () => {
  const response = await api.get<any>(`/v1/admin/user/prop-players/sessions`);
  return response.data;
};

export const fetchUsersRest = async (getUsereDto : GetUserDto) => {
  const response = await api.get<UserResponseDto>(`/v1/admin/user/`, {
    params: { ...getUsereDto },  
  });
  return response.data;
};

export const fetchUserByIdRest = async (userId: string) => {
  const response = await api.get<UserInfo>(`/v1/admin/user/${userId}/details`);
  return response.data
};

export const updateUserRest = async (userId: string, user: UpdateUserDto) => {

  return await api.put(`/v1/admin/user/${userId}`, {
    params: user,
  });
};

export const fetchUsersCount = async (query: UserQueryDto) => {
  const response = await api.get(`/v1/admin/user/analytics/users-count`, {
    params: query,
  });
  return response.data;
};

export const fetchNewUsersByDay = async (query: UserQueryDto) => {
  const response = await api.get(`/v1/admin/user/analytics/new-users-by-day`, {
    params: query,
  });
  return response.data
};

export const fetchUsersByCountry = async (query: UserQueryDto) => {
  return await api.get(`/v1/admin/user/analytics/users-by-country`, {
    params: query,
  });
};

export const fetchUsersByDevice = async (query: UserQueryDto) => {
  return await api.get(`/v1/admin/user/analytics/users-by-device`, {
    params: query,
  });
};

export const fetchUsersByBrowserName = async (query: UserQueryDto) => {
  return await api.get(`/v1/admin/user/analytics/users-by-browser-name`, {
    params: query,
  });
};

export const fetchUsersLogInByDate = async (query: UserQueryDto) => {
  const response = await api.get(`/v1/admin/user/analytics/users-log-in-by-date`, {
    params: query,
  });
  return response.data
};
