import { Config } from "./config.interface";
import { localConfig } from "./local.config";
import { developConfig } from "./develop.config"
import { stagingConfig } from "./staging.config";
import { productionConfig } from "./production.config";

let config: Config;

switch (process.env.REACT_APP_NODE_ENV) {
  case "production":
    config = productionConfig;
    break;
  case "staging":
    config = stagingConfig;
    break;
  case "development":
    config = developConfig;
    break;
  default:
    config = productionConfig;
}

export default config;

