import { polygon } from "viem/chains";
import { Config } from "./config.interface";
import { baseConfig } from "./base.config";

export const stagingConfig: Config = {
  ...baseConfig,
  environment: "staging",
  isDevelopment: false,
  isProduction: true,
  urls: {
    appBaseUrl: "https://staging.admin.phenompoker.com/",
    phenomApi: "https://staging.api.phenompoker.com/",
    phenomApp: "https://staging.app.phenompoker.com/",
  },
  chain: polygon,
  blockExplorerUrl: "https://polygonscan.com",
  usdtDecimals: 18,
  phnmDecimals: 18,
  contracts: {
    usdt: '0x2A1e0E339355899f5F7f7c1F4590a79D6B49D4F2',
    phnm: '0xE24D8d07AC6c54Ff358b00c1Bc5dB4A6f73a42cb',
    tables: '0xe789D2724f29D8De7FA00a4e133b9072d6A492D9',
    revenueShareManager: '0x0525eF96B48F8b8Be5e5Dda42305f67e3F083499',
    rakeManager: '0xdA6883699f031F438ceBac69163aD7636490929E',
    redemptionManager: '0xD6Bb3F4576f341Bbc8DbC9d0a302205C643783a8',
    redemptionVault: '0x0cACa68A160b2c43C248C1E2b045c3e09C2E02c2',
    rewardsManager: '0x78750F3266d12De47273d0373F6c561c51116366',
    safeTreasury: '0xc4BB12CFde0DC6F2Bb4e0bF83325Cf20878aD1BE',
    orchestrator: '0x276d28120Bc89FDFf8E52f23B025A2cb53615e0A',
    redemption: '0xe28C4129828e8a0900e3AD01BB9419e13815aA15',
    smartAccount: "0x8310a1bBB27f29935008BDc8BAc970d86B329d0a",
    backendDeployer: "0x03e4a8d78a23Bf8C0ef1DA6B16b18716d2477f3c"
  },
};
