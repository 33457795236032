import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";

import CopyField from "@/common/forms/CopyField";
import { showToast } from "@/common/showToast";
import TableNativeTokenBalanceBalance from "@/components/TableNativeTokenBalance";
import PlayerNativeTokenBalance from "@/components/PlayerNativeTokenBalance";
//import PlayerStack from "@/components/PlayerStack";
import config from "@/config/index";
import routes from "@/routes/routes-enums";
import {
  fetchTableByIdSessions,
  deletePlayersContractRest,
  getPlayersContractRest,
  deletePlayerContractRest,
  refundTableRest,
  recoverTableRest,
} from "@/services/table/table-service";
import { SeatDto, TableInfoDto, Session } from "@/services/table/table-types";
import PlayerStack from "@/components/PlayerStack";
import TransactionsTable from "./components/TransactionsTable";
import { toast } from "react-toastify";

interface RemovePlayerProps {
  smartWalletAddress: string;
  tableId: string;
  contractAddress: string | undefined;
  removing: boolean;
  setRemoving: (status: boolean) => void;
  setPlayersContracts: React.Dispatch<React.SetStateAction<string[]>>;
}

const KickPlayerSuccessToast = ({ txHash }: { txHash: string }) => {
  return (
    <div>
      <p>Player kicked successfully.</p>
      <CopyField
        className="w-100"
        name="txHash"
        label={false}
        value={txHash}
        link={`${config.blockExplorerUrl}/tx/${txHash}`}
      />
    </div>
  );
};

const RemovePlayer: React.FC<RemovePlayerProps> = ({
  smartWalletAddress,
  tableId,
  contractAddress,
  removing,
  setRemoving,
  setPlayersContracts,
}) => {
  const [amount, setAmount] = useState<string>("0");

  const removePlayer = (tableId: string, amount: string, address: string) => {
    setRemoving(true);
    deletePlayerContractRest(tableId, amount, address)
      .then((response) => {
        if (typeof response !== "string") {
          throw new Error("error deleting contract player");
        }

        toast.success(<KickPlayerSuccessToast txHash={response} />, {
          pauseOnHover: true,
          autoClose: false,
          closeOnClick: false,
          hideProgressBar: true,
        });
        return getPlayersContractRest(tableId);
      })
      .then((response) => {
        setPlayersContracts(response);
      })
      .catch((error) => {
        showToast("Error when removing players", "warning");
      })
      .finally(() => {
        setRemoving(false);
      });
  };
  return (
    <div className="d-flex flex-row gap-3" style={{ alignItems: "center" }}>
      <div className="d-flex flex-row" style={{ alignItems: "center" }}>
        <PlayerNativeTokenBalance
          tableId={tableId}
          smartWalletAddress={smartWalletAddress}
          contractAddress={contractAddress}
          tokenSymbol="USDT"
          decimals={config.usdtDecimals}
        />
        <span className="mx-2">USDT</span>
      </div>

      <div className="d-flex flex-row" style={{ alignItems: "center" }}>
        <input
          type="number"
          className="form-control"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <button
          className="btn btn-sm btn-secondary mx-2"
          onClick={() => removePlayer(tableId, amount, smartWalletAddress)}
          disabled={removing}
        >
          {removing ? <Spinner size="sm" /> : "Remove"}
        </button>
      </div>
    </div>
  );
};

const AddFundsTable = ({ tableId }: { tableId: string }) => {
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<string | undefined>(undefined);

  const handleSubmit = async () => {
    if (!amount) {
      return;
    }
    setSubmitting(true);
    await refundTableRest(tableId, amount);
    setSubmitting(false);
  };

  return (
    <tr>
      <th className="w-50">Add Funds</th>
      <td className="w-50">
        <input
          className="p-2"
          placeholder="Amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value.toString())}
        />
        <button
          className="btn btn-sm btn-secondary mx-2 p-2"
          onClick={handleSubmit}
        >
          {submitting ? <Spinner size="sm" /> : "Add"}
        </button>
      </td>
    </tr>
  );
};

const RemoveFundsTable = ({ tableId }: { tableId: string }) => {
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<string | undefined>(undefined);

  const handleSubmit = async () => {
    if (!amount) {
      return;
    }
    setSubmitting(true);
    await recoverTableRest(tableId, amount);
    setSubmitting(false);
  };

  return (
    <tr>
      <th className="w-50">Remove funds</th>
      <td className="w-50">
        <input
          className="p-2"
          placeholder="Amount"
          type="number"
          onChange={(e) => setAmount(e.target.value.toString())}
        />
        <button
          className="btn btn-sm btn-secondary mx-2 p-2"
          onClick={handleSubmit}
        >
          {submitting ? <Spinner size="sm" /> : "Remove"}
        </button>
      </td>
    </tr>
  );
};

interface TableDetailsGameProps {
  table: TableInfoDto;
  isStudGame: boolean;
  isLimitGame: boolean;
  refreshTable?: () => void;
}

const TableDetailsGame: React.FC<TableDetailsGameProps> = ({
  refreshTable,
  table,
  isStudGame,
  isLimitGame,
}) => {
  const { tableId } = useParams<{ tableId: string }>();
  const navigate = useNavigate();
  const [playersContracts, setPlayersContracts] = useState<string[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [removing, setRemoving] = useState<boolean>(false);

  useEffect(() => {
    if (!tableId) return;

    fetchTableByIdSessions(tableId)
      .then((response) => {
        setSessions(response);
      })
      .catch((error) => {
        showToast("Error fetching sessions", "warning");
      });

    getPlayersContractRest(tableId)
      .then((response) => {
        setPlayersContracts(response);
      })
      .catch((error) => {
        showToast("Error when fetching contract players", "warning");
      });
  }, [tableId]);

  const removePlayers = () => {
    if (!tableId) return;
    setRemoving(true);
    deletePlayersContractRest(tableId)
      .then(() => {
        showToast("Players removed from table", "success");
        getPlayersContractRest(tableId)
          .then((response) => {
            setPlayersContracts(["350857b3-423f-4429-a808-5c5a7d3cc8c3"]);
            setPlayersContracts(response);
          })
          .catch((error) => {
            showToast("Error when fetching contract players", "warning");
          });
      })
      .catch((error) => {
        showToast("Error when removing players", "warning");
      })
      .finally(() => {
        setRemoving(false);
      });
  };

  const renderTransactionsTable = useMemo(() => {
    return (
      <TransactionsTable
        transactions={table?.transactions || []}
        refreshTable={refreshTable}
      />
    );
  }, [table?.transactions]);

  return (
    <div className="w-full">
      <h3>Table Summary</h3>
      <table className="table table-hover table-centered align-middle table-nowrap mb-5">
        <tbody>
          <tr>
            <th className="w-50">Uncollected Rake</th>
            <td className="w-50">${table?.uncollectedRake?.toFixed(2)}</td>
          </tr>
          <tr>
            <th className="w-50">Table Stack</th>
            <td className="w-50">
              $
              {table?.seats?.reduce(
                (acc, seat) => acc + seat.stack + (seat?.extraStack || 0),
                0
              ) || 0}
            </td>
          </tr>
          <tr>
            <th>Hands dealt</th>
            <td>{table?.handsDealt}</td>
          </tr>
          <tr>
            <th>Average Pot</th>
            <td>${table?.avgPot}</td>
          </tr>
          {table && table.isRealMoney && (
            <>
              <tr>
                <th>Contract Id: {table.contractId} </th>
                <td>
                  <CopyField
                    label={false}
                    name="Table Contract"
                    value={table.contractAddress}
                    link={`${config.blockExplorerUrl}/address/${table.contractAddress}`}
                  />
                </td>
              </tr>
              <tr>
                <th>Balance in Contract</th>
                <td>
                  <TableNativeTokenBalanceBalance
                    tableContractId={table.contractId}
                    contractAddress={table.contractAddress}
                    tokenSymbol="USDT"
                    decimals={config.usdtDecimals}
                  />{" "}
                  USDT
                </td>
              </tr>
              {playersContracts.length > 0 && (
                <tr>
                  <th>Players in Contract</th>
                  <td>
                    <span className="mx-2">{playersContracts.length}</span>
                    <button
                      className="btn btn-sm btn-primary mx-2"
                      onClick={() => removePlayers()}
                      disabled={true}
                    >
                      {removing ? <Spinner size="sm" /> : "Remove Players"}
                    </button>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>

      {playersContracts.length ? (
        <>
          <h3>Contract Players</h3>
          <table
            className="table table-hover table-centered align-middle table-nowrap mb-5"
            style={{ borderTop: "2px solid #000" }}
          >
            <thead>
              <tr>
                <th>Address</th>
                <th>Alias</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {playersContracts.map((player: any) => (
                <tr key={player.id}>
                  <td>{player.smartWalletAddress}</td>
                  <td>{player.alias}</td>
                  <td>
                    <RemovePlayer
                      smartWalletAddress={player.smartWalletAddress}
                      tableId={tableId!}
                      contractAddress={table.contractAddress}
                      removing={removing}
                      setRemoving={setRemoving}
                      setPlayersContracts={setPlayersContracts}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}

      {table?.seats && (
        <>
          <h3>Players Summary</h3>
          <table
            className="table table-hover table-centered align-middle table-nowrap mb-5"
            style={{ borderTop: "2px solid #000" }}
          >
            <thead>
              <tr>
                <th>Seat #</th>
                <th>Alias</th>
                <th>Stack</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {table?.seats?.map((seat: SeatDto) => {
                return (
                  <tr key={seat.seat}>
                    <td>{seat.seat}</td>
                    <td>{seat.user?.alias}</td>
                    <td>{seat.stack + (seat.extraStack || 0)}</td>
                    <td>{seat?.user?.smartWalletAddress}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}

      <h3>Sessions</h3>
      <table
        className="table table-hover table-centered align-middle table-nowrap mb-5"
        style={{ borderTop: "2px solid #000" }}
      >
        <thead>
          <tr>
            <th>User</th>
            <th>Stack</th>
            <th>Contract Balance</th>
            <th>Hands Dealt</th>
            <th>Created At</th>
            <th>Closed At</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session: Session) => (
            <tr key={session.id}>
              <td>
                <span
                  onClick={() =>
                    navigate(`${routes.USER_EDIT}/${session.userId}`)
                  }
                >
                  {session.user.alias}
                </span>
              </td>
              <td>{session.stack}</td>
              <td>
                {session.user.smartWalletAddress && table.contractId && (
                  <PlayerStack
                    tableId={table.contractId}
                    contractAddress={table.contractAddress}
                    userWallet={session.user.smartWalletAddress}
                    tokenSymbol="USDT"
                    decimals={config.usdtDecimals}
                  />
                )}
              </td>
              <td>{session.handsDealt}</td>
              <td>{session.createdAt}</td>
              <td>{session.closedAt}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {renderTransactionsTable}

      {tableId && (
        <>
          <h3>Table Actions</h3>
          <table className="table table-hover table-centered align-middle table-nowrap mb-5">
            <tbody>
              <AddFundsTable tableId={tableId} />
              <RemoveFundsTable tableId={tableId} />
            </tbody>
          </table>
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default TableDetailsGame;
