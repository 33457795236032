import { useState } from "react";
import { TransactionsDto, UpdateTransactionDto } from "@/services/transactions/transactions-types";
import CopyField from "@/common/forms/CopyField";
import config from '@/config/index';
import { FaPencilAlt, FaArrowDown, FaArrowUp } from "react-icons/fa";
import { Button, Modal, ModalBody, ModalHeader, Row, Col, Input } from 'reactstrap';
import { TransactionStatus } from '@/services/transactions/transactions-types';
import { updateTableTransactionRest } from "@/services/table/table-service";
import { showToast } from "@/common/showToast";
import { format } from "date-fns";
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel
  } from "@tanstack/react-table";

const EditTransactionModal = ({ refreshTable, selectedTx, closeModal }: { refreshTable?: () => void; selectedTx: TransactionsDto, closeModal: () => void }) => {
    const [editTxDto, setEditTxDto] = useState<UpdateTransactionDto>({
        status: selectedTx.status,
        txId: selectedTx.txId,
        message: selectedTx.message,
    });

    const handleChange = (key: string, value: string) => {
        setEditTxDto({
            ...editTxDto,
            [key]: value
        })
    }

    const handleSubmit = async () => {
        await updateTableTransactionRest(selectedTx.id, editTxDto);
        closeModal();
        showToast('Transaction updated successfully', 'success');
        if (refreshTable) {
            refreshTable();
        }
    }

    return (
        <Modal
            isOpen
            backdrop="static"
            id="editTx"
            centered        
        >
                    <ModalHeader className="bg-dark p-3" toggle={closeModal}>
                        Edit Transaction
                    </ModalHeader>
                    <ModalBody>
                        <Col>
                            <Col className="mb-4">
                                <Row>
                                    <div className="form-field">
                                        <label htmlFor="status" >
                                            Status
                                        </label>
                                        <select value={editTxDto.status} className="form-control" onChange={e => handleChange('status', e.target.value)}>
                                            <option value=""></option>
                                            {Object.keys(TransactionStatus).map(type => (
                                                <option>
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </Row>
                            </Col>
                            <Col className="mb-4">
                                <Row>
                                    <div className="form-field">
                                        <label htmlFor="txId" >
                                            Transaction Id
                                        </label>
                                        <Input onChange={e => handleChange('txId', e.target.value)} className={`form-control`} value={editTxDto.txId} />
                                    </div>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <div className="form-field">
                                        <label htmlFor="message" >
                                            Message
                                        </label>
                                        <textarea onChange={e => handleChange('message', e.target.value)} className={`form-control`} value={editTxDto.message} />
                                    </div>
                                </Row>
                            </Col>
                        </Col>
                        <Col className="my-2 mt-4 ">
                            <Button color="grey" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </ModalBody>
                </Modal>
    );
}

const TransactionsTable = ({ refreshTable, transactions }: { refreshTable?: () => void; transactions: TransactionsDto[] }) => {
    const [selectedTx, setSelectedTx] = useState<TransactionsDto | null>(null);


    const openEditModal = (tx: TransactionsDto) => {
        setSelectedTx(tx);
    }

    const table = useReactTable({
        data: transactions,
        columns: [
            {
                accessorKey: 'createdAt',
                header: 'Date',
                cell: info => format(info.getValue(), 'dd MMM yyyy HH:mm'),
                sortDescFirst: false,
            },
            {
                accessorKey: 'txId',
                header: 'Transaction ID',
                sortUndefined: 'last',
                cell: info => {
                    const cellData = info.getValue();
                    return (
                        <CopyField
                            className="w-100"
                            name="txId"
                            label={false}
                            value={cellData}
                            link={`${config.blockExplorerUrl}/address/${cellData}`}
                        />
                    );
                },
                size: 200,
            },
            {
                accessorKey: 'status',
                header: 'Status'
            },
            {
                accessorKey: 'type',
                header: 'Type'
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
                cell: info => `${Number(info.getValue() || 0).toFixed(2)}`
            },
            {
                accessorKey: 'addressFrom',
                header: 'From',
                cell: info => {
                    const cellData = info.getValue();
                    return (
                        <CopyField
                            className="w-100"
                            name="txId"
                            label={false}
                            value={cellData}
                            link={`${config.blockExplorerUrl}/address/${cellData}`}
                        />
                    );
                },
                size: 200,
            },
            {
                accessorKey: 'addressTo',
                header: 'To',
                cell: info => {
                    const cellData = info.getValue();
                    return (
                        <CopyField
                            className="w-100"
                            name="txId"
                            label={false}
                            value={cellData}
                            link={`${config.blockExplorerUrl}/address/${cellData}`}
                        />
                    );
                },
                size: 200,
            },
            {
                header: 'Actions',
                cell: (info) => (
                    <Button color="primary" onClick={() => openEditModal(info.row.original)}>
                        <FaPencilAlt />
                    </Button>
                )
            }
        ],
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: {
            sorting: [
                {
                    id: 'createdAt',
                    desc: true,
                }
            ]
        }
    });

    return (
        <div className="my-4">
            <h3>Transactions</h3>
            {selectedTx && (
                <EditTransactionModal selectedTx={selectedTx} closeModal={() => setSelectedTx(null)} refreshTable={refreshTable} />
            )}
            <div className="py-2">
                <table className="table table-hover table-centered align-middle table-nowrap" style={{ borderTop: "2px solid #000" }}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup?.headers.map(header => (
                                    <th 
                                        role="button" 
                                        key={header.id} 
                                        colSpan={header.colSpan} 
                                        onClick={header.column.getToggleSortingHandler()}
                                        style={{ width: `${header.getSize()}px` }}
                                    >
                                        <div className="d-flex justify-content-start align-items-start gap-2">
                                            <div>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </div>
                                            <div>
                                                {{
                                                    asc: <FaArrowDown />,
                                                    desc: <FaArrowUp />,
                                                    default: (
                                                        <span>
                                                          <FaArrowDown/>
                                                          <FaArrowUp />
                                                        </span>
                                                    )
                                                }[header.column.getIsSorted() as string || 'default']}     
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Col>
                <Col>
                    <div>Page</div>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount().toLocaleString()}
                    </strong>
                </Col>
                <Col className="my-2">
                    <Button
                        
                        color='primary'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        Prev
                    </Button>
                    <Button
                        className="mx-2"
                        color='primary'
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        Next
                    </Button>
                </Col>
            </Col>
        </div>
    )
}

export default TransactionsTable;
