import { AgreementsStrategyConfig } from "./agreements-config.interface";
import { localConfig } from "./local.config";
import { developConfig } from "./develop.config";
import { stagingConfig } from "./staging.config";
import { productionConfig } from "./production.config";

let agreementsConfig: AgreementsStrategyConfig;

switch (process.env.NODE_ENV) {
    case "production":
        agreementsConfig = productionConfig;
        break;
    // case "staging":
    //     agreementsConfig = stagingConfig;
    //     break;
    case "development":
        agreementsConfig = developConfig;
        break;
    default:
        // agreementsConfig = stagingConfig;
        agreementsConfig = localConfig;
}

export default agreementsConfig;
